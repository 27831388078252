<!-- eslint-disable no-param-reassign -->
<template>
  <b-card class="py-2 px-2">
    <b-row class="align-items-end mb-2">
      <b-col
        class="mb-1 mb-sm-0"
        cols="12"
        md="4">
        <DatePickerFilter
          v-model="filter.startDate"
          label="Start Date" />
      </b-col>
      <b-col
        class="mb-1 mb-sm-0"
        cols="12"
        md="4">
        <DatePickerFilter
          v-model="filter.endDate"
          label="End Date" />
      </b-col>
      <b-col
        sm="12"
        md="4"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="w-100 mt-2"
          variant="primary"
          @click="submitExport()"
        >
          <span class="align-middle">Export</span>
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import FilterDropdown from '@/components/Filter/FilterDropdown.vue'
import DatePickerFilter from '@/components/Filter/DatePickerFilter.vue'
import ReportProvider from '@/resources/ReportProvider'
import dayjs from 'dayjs'

const ReportService = new ReportProvider()

export default {
  components: {
    DatePickerFilter,
    FilterDropdown
  },
  data () {
    return {
      filter: {
        startDate: dayjs().format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD')
      }
    }
  },
  methods: {
    async submitExport () {
      try {
        await ReportService.complainReport(this.filter)
        // const { data } = await ReportService.ticketDetailNewReport(this.filter)
        // this.exportCSV(data)
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select {
  background: white;
}
.card-body {
  padding: 0;
}
::v-deep input.vs__search::placeholder {
  color: #B9B9C3;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
</style>
